import styles from './HelpDeskChat.module.scss'
import {
  Link, useParams
} from "react-router-dom";
import { BiSupport } from "react-icons/bi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";
import { AuthContext } from '../../contexts/AuthContext';
import bg_helpdesk from '../../images/bg_helpdesk.png'
import { useContext, useEffect, useState, useRef } from 'react';
import bgchat from '../../images/bg_chat.png'
import api from '../../services/api'
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

function HelpDeskChat() {
  const navigate = useNavigate();
  const [chatFiles, setChatFiles] = useState([]);
  const [chat, setChat] = useState([]);
  const [socket, setSocket] = useState({});
  const [listaNovas, setListaNovas] = useState([]);
  const [day, setDay] = useState({});
  const [isConnected, setIsConnected] = useState(null);
  const [token, setToken] = useState();
  const { id } = useParams();
  const [message, setMessage] = useState('');
  const { user, getToken } = useContext(AuthContext);
  const chatScroll = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.post('/helphistorico/' + id)
      .then(res => {
        setChat(res.data.retorno.chat);
        setIsActive(res.data.retorno.ativo);
        scrollDown();
        listaArquivos();
        setLoading(false);
      }).catch(err => {
        console.log(err);
      })

    connectSocket();

    if (!isActive) {
      return;
    }


  }, [])

  function connectSocket() {
    setLoading(true);
    const socketX = new WebSocket('wss://wsci.spread.education/app/protocolo'); // prod
    //const socketX = new WebSocket('wss://wsci2.spread.education/app/protocolo2'); // teste

    socketX.addEventListener('message', function (event) {
      const data = JSON.parse(event.data);
      if (data.protocolo == id) {
        if (data.idmensagem > 0 && !listaNovas[data.idmensagem]) {
          const userToken = localStorage.getItem('CITOKEN')
          console.log(userToken);
          const currentDateFormatted = moment(data.data).format('YYYY-MM-DD HH:mm:ss');
          setChat(prevChat => [
            ...prevChat,
            {
              data: 'hoje',
              dono: data.token == userToken,
              hora: currentDateFormatted.split(' ')[1],
              mensagem: data.mensagem,
              nome: data.nome,
            }
          ]);
          let newList = listaNovas;
          newList[data.idmensagem] = true;
          setListaNovas(newList);

          if (chatScroll.current) {
            console.log('scrolling dowxn')
            const { scrollHeight, clientHeight } = chatScroll.current;
            chatScroll.current.scrollTop = scrollHeight - clientHeight;
          }
        }
      }
    })

    setSocket(socketX);
    setLoading(false);
  }

  async function getHistorico() {
    setLoading(true);
    api.post('/helphistorico/' + id)
      .then(res => {
        setChat(res.data.retorno.chat);
        setIsActive(res.data.retorno.ativo);
        scrollDown();
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
  }

  function listaArquivos() {
    setLoading(true);
    api.post('/listfiles/' + id, {
      'protocolo': id
    }).then(res => {
      setLoading(false);
      if (res.data.sucesso) {
        setChatFiles(res.data.retorno);
      } else {
        toast.error(res.data.mensagem);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  async function closeHelp() {
    setLoading(true);
    api.post('/closehelp/' + id, {
      'protocolo': id
    }).then(res => {
      setLoading(false);
      if (res.data.sucesso) {
        toast.success(res.data.mensagem);
        getHistorico();
      } else {
        toast.error(res.data.mensagem);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  function scrollDown() {
    if (chatScroll.current) {
      console.log('scrolling down')
      const { scrollHeight, clientHeight } = chatScroll.current;
      chatScroll.current.scrollTop = scrollHeight - clientHeight;
    }
  }

  function handleMessageChange(e) {
    setMessage(e.target.value);
  }

  function handleChangeUpload(e) {

    const selectedFile = e.target.files[0];
    const larid = e.target.getAttribute('data-id');
    if (!selectedFile) {
      toast.error('Nenhum arquivo selecionado.');
      return;
    }

    if (selectedFile.size > 5 * 1024 * 1024) {
      toast.error('O arquivo excede o tamanho permitido.');
      return;
    }

    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf', '.xls', '.doc', '.csv'];
    const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

    if (allowedExtensions.indexOf(fileExtension) === -1) {
      toast.error('Formato inválido, somente é permitido arquivos de imagem ou pdf.');
      return;
    }

    const data = new FormData();
    data.append('file', selectedFile);
    data.append('idProtocolo', id)
    api.post('/uploadhelp/', data)
      .then((res) => {
        console.log(res);
        if (res.data.sucesso) {
          toast.success(res.data.mensagem);
          listaArquivos();
          getHistorico();
        } else {
          toast.error(res.data.mensagem);
        }
        console.log(res);
      }).catch((err) => {

      })
  }

  function sendMessage() {
    console.log(message, 'enviando')
    if (message === '') {
      return;
    }

    if (socket.readyState == 1) {
      const currentDate = moment(new Date());
      const currentTimeFormattedX = currentDate.add(3, 'hours');
      const currentDateFormatted = moment(currentTimeFormattedX).format('YYYY-MM-DD HH:mm:ss');
      const userToken = localStorage.getItem('CITOKEN')
      let data = {
        token: userToken,
        appid: 4,
        data: currentDateFormatted,
        nome: user.nome,
        tipoArquivo: 'texto',
        encode: '',
        protocolo: id,
        mensagem: message
      }

      socket.send(JSON.stringify(data));
      setMessage('');
    } else {
      connectSocket();
      toast.error("O servidor de mensagens não está funcionando. Recarregue a página.");
    }
  }

  const bgStyle = {
    backgroundImage: `url(${bg_helpdesk})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
  };

  const bgChatStyle = {
    backgroundImage: `url(${bgchat})`,
    backgroundPosition: 'center',
    overflow: 'hidden'
  };

  let dayArr = {};

  return (
    <>
      {loading ? <div className={styles.loading}><AiOutlineLoading3Quarters /></div> :
        <>
          <div className={styles.bg} style={bgStyle}>
            <BiSupport style={{ fontSize: '45px' }} />
            <div>
              <h1>Estamos aqui para te ajudar</h1>
            </div>
          </div>

          <div className={styles.container}>
            <>
              <div className={styles.headerList}>
                <h2><BiSupport /> <span>Solicitação #{id}</span></h2>
                <button onClick={() => navigate(-1)}><RiArrowGoBackFill /></button>
              </div>
              <div className={styles.warning}>As solicitações podem levar até 48 horas para serem respondidas.</div>
              <div className={styles.chat} style={bgChatStyle}>

                <div ref={chatScroll} className={styles.chatArea}>

                  <>
                    {chat.map(c => {
                      let datax = c.data !== 'hoje' ? moment(new Date(c.data)).subtract(3, 'hours').format('DD/MM/YYYY') : 'hoje';
                      let dataHoje = moment(new Date()).add(3, 'hours').format('DD/MM/YYYY');
                      let dataFinal = datax === dataHoje ? 'hoje' : datax
                      console.log('comparando =>', datax, dataHoje)
                      let mostrar = !dayArr[dataFinal];
                      dayArr[dataFinal] = true;
                      const currentTime = moment(c.hora, 'HH:mm:ss');
                      const currentTimeFormatted = currentTime.subtract(3, 'hours');
                      return <>
                        {mostrar && <div key={c.mensagem} className={styles.dayDivider}>{dataFinal}</div>}
                        {c.dono === true ?
                          <div className={`${styles.messageUser} ${styles.message}`}>
                            <span dangerouslySetInnerHTML={{ __html: c.mensagem }} />
                            <span className={styles.timestamp}>{currentTimeFormatted.format('HH:mm')}</span>
                          </div>
                          :
                          <div className={`${styles.messageAdmin} ${styles.message}`}>
                            <span className={styles.nome}>{c.nome}</span>
                            <span dangerouslySetInnerHTML={{ __html: c.mensagem }} />
                            <span className={styles.timestamp}>{currentTimeFormatted.format('HH:mm')}</span>
                          </div>
                        }
                      </>
                    })
                    }

                  </>

                </div>

                {isActive ?
                  <>
                    <div className={styles.inputMessage}>
                      <textarea value={message} onChange={handleMessageChange} placeholder='Escreva sua mensagem'></textarea>
                    </div>


                    <div className={styles.inputButton}>
                      <button className={styles.buttonClose} onClick={closeHelp}>Finalizar Atendimento</button>
                      <button className={styles.buttonMessage} onClick={sendMessage}>Enviar</button>
                    </div>
                  </>
                  :
                  <div></div>
                }
                <br></br>
                <div className={styles.files}>
                  <h5>Lista de arquivos</h5>
                  <div>
                    {chatFiles?.length > 0 ? <>
                      {chatFiles?.map(c => {

                        return <>
                          <a target="_blank" href={c.link}>{c.nome} {c.data} {c.tamanho}</a>
                        </>
                      })}
                    </>
                      :
                      <span>Nenhum arquivo encontrado.</span>
                    }
                  </div>

                  {isActive &&
                    <form encType='multipart/form-data' className={styles.docList}>
                      <input onChange={handleChangeUpload} type="file" />
                    </form>
                  }
                </div>
              </div>

            </>

          </div>
        </>
      }
    </>
  );
}

export default HelpDeskChat;
