import styles from './QuoteItemDetail.module.scss'
import { BsFillBagCheckFill } from 'react-icons/bs';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters} from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../services/api'


function QuoteItemDetail() {
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState({});
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    api.get('/quote/' + id)
    .then((res) => {
      setQuote(res.data);
      console.log(res.data)
      setLoading(false);
      console.log(res);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    })
  }, [id])


  return (
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><BsFillBagCheckFill/> Detalhes do orçamento</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
        <>
        { quote !== {} &&
        
        <div className={styles.list}>
          <div dangerouslySetInnerHTML={{ __html: quote }} />
        </div>

        }
        </>
      }
    </div>
  );
}

export default QuoteItemDetail;
