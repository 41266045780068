import Footer from '../../../components/Footer';
import Header from '../../../components/Header'
import Quiz from '../../../components/Quiz'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom';

export default function CustomerSuccess() {
  const { redirect } = useParams();

  useEffect(() => {
    if(redirect){
      localStorage.setItem('CIREDIRECT', '/customersuccess')
    }
  })

  return (
    <>
        <Header/>
        <Quiz/>
        <Footer/>
    </>
  )
}