import styles from './QuotesList.module.scss'
import { BsFillBagCheckFill } from 'react-icons/bs';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'
import { useState, useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

function QuotesList() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);

  useEffect(() => {
    getQuotes(true);
  }, [])

  async function getQuotes(first = false) {
    if(loading && !first) { return; }
    setLoading(true);
    let itemsQuery = await api.post('/quotes/' + offset + '/' + limit);
    if(itemsQuery.data.sucesso) {
      setItems(itemsQuery.data.retorno);
      setLoading(false)
    }else{
      console.log('errror');
      setLoading(false);
    }
  }

  // window.addEventListener('scroll', () => {
  //   const scrollY = window.scrollY;
  //   const docHeight = document.documentElement.scrollHeight;
  //   const viewportHeight = window.innerHeight;

  //   if (scrollY + viewportHeight >= docHeight) {
  //     setOffset(offset + 10);
  //     setLimit(10);
  //     getQuotes(false);
  //   }
  // });



  const navigate = useNavigate();
  return (
    <>
    { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><BsFillBagCheckFill/> Meus orçamentos</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      <div className={styles.list}>
        {items?.length > 0 ?
        <>
        {items?.map(item => {
          return <>
           <div className={styles.listItem}>
            <div className={styles.subItem}>
              <div>
                <span className={styles.orange}>Orçamento nº</span>
                <span className={styles.grey}>{item.id}</span>
              </div>
              <div>
                <span className={styles.orange}>Data</span>
                <span className={styles.grey}>{item.datalancamento}</span>
              </div>
            </div>

            <div className={styles.subItem}>
              <div>
                <span>Valido até</span>
                <span className={styles.grey}>{item.datavalidade}</span>
              </div>
            </div>

            <div className={`${styles.subItem} ${styles.border}`}>
              <div>
                <span>Origem do orçamento</span>
                <span className={styles.grey}>{item.unidade}</span>
              </div>
              <div>
                <span>Consultor CI</span>
                <span className={styles.grey}>{item.consultor}</span>
              </div>
            </div>

            <div className={styles.subItem}>
              <div>
                <span>Tipo intercâmbio</span>
                <span className={styles.grey}>{item.tipoprograma}</span>
              </div>
            </div>

            <div className={styles.subItem}>
              <div>
                <span>Escola</span>
                <span className={styles.grey}>{item.escola}</span>
              </div>
            </div>

            <div className={styles.subItem}>
              <div>
                <span>Destino</span>
                <span className={styles.grey}>{item.destino}</span>
              </div>
              <div>
                <span>Período programa</span>
                <span className={styles.grey}>{item.deate}</span>
              </div>
            </div>

            <div className={styles.subItem}>
              <div>
                <span>Fornecedor</span>
                <span className={styles.grey}>{item.fornecedor}</span>
              </div>
            </div>

            <div className={styles.total}>
              <span>Valor total:</span>
              <span className={styles.orange}>{item.moeda} {item.valortotal}</span>
            </div>
            {/* <button onClick={() => navigate('/quotes/detail/' + item.id)}>Visualizar orçamento completo</button> */}
            <button onClick={() => navigate('/quotes/detail/' + item.id)}>Visualizar orçamento completo</button>
          </div> 
        </>
      })}  
      </>
      : 'Não encontramos nenhum orçamento.'}   
      </div>
    </div>
    }
    </>

  );
}

export default QuotesList;
