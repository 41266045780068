import styles from './HelpDesk.module.scss'
import { RiBillLine } from 'react-icons/ri';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../services/api'

function HelpDesk() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {  

    api.post('/helpdesklist/')
    .then((res) => {
      if(res.data.retorno.length === 0){
        setLoading(false);
        return
      }

      setItems(res.data.retorno);

      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    })
  }, [])

  function openChat(id) {
    navigate('/helpdesk/chat/' + id)
  }

  function openNew() {
    navigate('/helpdesk/new/')
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><BiSupport/> Minhas solicitações</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
      <>

          <button className={styles.new} onClick={openNew}>Nova solicitação</button>

          <div className={styles.warning}>As solicitações podem levar até 48 horas para serem respondidas.</div>

          {items?.length > 0 ? 

          <><div className={styles.saleWrapper} >

          <div className={styles.listWrapper}>  
            {items.map(item => {
                      return <>
                          <div className={styles.billBox} key={item.ptrrqaid}>
                            <section>
                              <h3>SOLICITAÇÃO #{item.ptrid}</h3>
                              <article>
                                <span><b>Compra:</b> {item.ptrrqaid}</span>
                                <span><b>Assunto:</b> {item.ptrtitulo}</span>
                                <span><b>Último contato:</b> {item.ptrdataultimocontato}</span>
                              </article>


                              <button onClick={() => openChat(item.ptrid)}>Ver conversa <BiSupport/></button>
                              
                            </section>
                          </div>
                      </>                           
                        
                      })}
                      </div>                                    
                  </div>
          </>
         :
              <>
                <div className={styles.listWrapper}>
                  <h3>Nenhuma solicitação encontrado.</h3>
                </div>
              </>
            }
        </>
      }
    </div>
  );
}

export default HelpDesk;
