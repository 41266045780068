import Footer from '../../../../components/Footer'
import Header from '../../../../components/Header'
import QuoteItemDetail from '../../../../components/QuoteDetail'

export default function QuoteDetail() {
  return (
    <>
        <Header/>
        <QuoteItemDetail/>
        <Footer/>
    </>
  )
}