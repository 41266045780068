import Header from '../../../components/Header'
import { useEffect, useState } from 'react'
import api from '../../../services/api';
import { toast } from 'react-toastify'
import FinancesMenu from '../../../components/FinancesMenu';
import Footer from '../../../components/Footer';

export default function Finances() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
  })

  return (
    <>
        <Header/>        
        <FinancesMenu/>
        <Footer/>
    </>
  )
}