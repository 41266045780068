import styles from './Header.module.scss'
import logo from '../../images/logo.png'
import {AiOutlineLogout, AiFillNotification} from 'react-icons/ai'
import {BiSupport} from 'react-icons/bi'
import {SlSocialInstagram, SlSocialFacebook} from 'react-icons/sl'
import {TiSocialYoutube} from 'react-icons/ti'
import {CgProfile} from 'react-icons/cg'
import {MdNotifications, MdNotificationsActive} from 'react-icons/md'
import { AuthContext } from '../../contexts/AuthContext';
import { useContext, useEffect, useState  } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../../services/api'
import { LimitAccessContext } from '../../contexts/LimitAccessContext'
import notificacoesIcon10 from '../../images/10.svg';
import notificacoesIcon3 from '../../images/3.svg';
import notificacoesIcon4 from '../../images/4.svg';
import notificacoesIcon9 from '../../images/9.svg';
import { FirstLoginContext } from '../../contexts/FirstLoginContext'


function Header() {
  const { checkFirstLogin } = useContext(FirstLoginContext)
  const { logOut, user, checkLogin } = useContext(AuthContext)
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  const { checkAccess } = useContext(LimitAccessContext);
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
    checkAccess();
    checkFirstLogin();
    api.post('/notifications')
    .then((res) => {
      if(res.data.sucesso){
        setNotifications(res.data.retorno);
      }
    }).catch((err) => {
      console.log('error', err);
    })
  }, [])

  function goNotifications() {
    navigate('/notifications')
  }

  function goProfile() {
    navigate('/profile')
  }

  function goHome() {
    navigate('/');
  }

  function toggleNotifications(){
    setShowNotifications(!showNotifications);
  }

  function handleClickNotification(not){
    api.post('/cleannotification/', {
      'idnotificacao': not.id
    }) .then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log('error', err);
    }).finally(() =>{
      console.log('redirect',not.rota)
      navigate(not.rota);
    })
  }

  function handleClickSeeAll(){
    api.post('/cleannotifications/')
    .then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log('error', err);
    }).finally(() =>{
      navigate('/notifications')
    })
  }

  return (
    <header className={styles.header}>

      {user !== null ?
        <div class={styles.black}>
          <div className={styles.containerSocial}>
            <div className={styles.social}>
              {/* <Link to="https://instagram.com/ci" target="_blank"><SlSocialInstagram /></Link>
              <Link to="https://www.facebook.com/CI.Intercambio" target="_blank"><SlSocialFacebook/></Link>
              <Link to="http://www.youtube.com/channel/UCiVUJJL7lf9U1Fel72zynpw?sub_confirmation=1" target="_blank"><TiSocialYoutube/></Link> */}
            </div>
            <div className={styles.actions}>
            <>
              <span onClick={goNotifications}>
                <img src={notificacoesIcon10} width={20} height={20}/>
                <>histórico de notificações</>
              </span>
              <span onClick={goProfile}>
                <CgProfile/> 
                <>perfil</>
              </span>
              <span onClick={logOut}>
                  <AiOutlineLogout/> 
                  <>sair</>
                </span>
              </>
            </div>
          </div>
        </div>
        : ''
        }
        <div className={styles.orange}>
          <div className={styles.container}>
            <div className={styles.logoBox}>
              <img onClick={goHome} className={styles.logo} src={logo} alt="CI Intercâmbio" />
              <span>
                <div>INTERCÂMBIO</div>
              </span>
            </div>
            {user !== null ?
              <div className={styles.notificationIcon} onClick={toggleNotifications}>
                {notifications?.length === 0
                  ?
                  ''
                  :
                  <div className={styles.shake}>
                    <span>{notifications.length} novas notificações</span>
                    {/* <MdNotificationsActive/> */}
                    <img src={notificacoesIcon3} width={20} height={20}/>
                  </div>
                  }
                  {showNotifications ? 
                  <div className={styles.notificationsList}>
                    {notifications?.length > 0 ?
                    <>
                      {notifications.map(not => {
                          return (
                            <div className={styles.item} onClick={() => handleClickNotification(not)}>
                              <span>{not.notificacao}</span>
                              <img src={notificacoesIcon4} width={20} height={20}/>
                            </div>
                          );
                        })}
                        <div className={styles.seeAll} onClick={() => handleClickSeeAll()}>
                          <span>
                            <img src={notificacoesIcon9} width={20} height={20}/>
                          <span>Ver todas</span>
                          </span>
                        </div>
                    </>
                    : 'Sem novas notificações.'}
                  </div>
                  : 
                  ''
                  }
              </div>
            : ''}
          </div>
        </div>
    </header>
  );
}

export default Header;
