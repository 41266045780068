import styles from './Validate.module.scss'
import { useForm } from 'react-hook-form'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import api from '../../services/api'
import { useNavigate, useParams } from 'react-router-dom';
import { BiSolidMessageSquareError } from "react-icons/bi";

import { useState, useEffect } from 'react'

import { toast } from 'react-toastify'
export default function Validate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { _nonce } = useParams();

    useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps

      api.post('/validate', {
        nonce: _nonce,
      }).then((response) => {
        if(response.data.sucesso){
          navigate('/');
          toast.success(response.data.mensagem);
        }else{
          toast.error(response.data.mensagem);
          setTimeout(setLoading(false), 1000);
        }
      }).catch((err) => {
        toast.success(err);   
      })
  }, [])

  return (
    <div className={styles.container}>
    { loading ?
      <div className={styles.loading}><AiOutlineLoading3Quarters/></div>

      :

      <div className={styles.box}>
        <BiSolidMessageSquareError/>
        <h3>Ocorreu um erro ao validar, tente novamente.</h3>
      </div>
    }


    </div>
  )
}