import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import QuotesList from '../../../components/QuotesList'

export default function Quotes() {
  return (
    <>
        <Header/>
        <QuotesList/>
        <Footer/>
    </>
  )
}