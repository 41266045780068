import Header from '../../../components/Header'
import { useNavigate } from 'react-router-dom'

import HelpDesk from '../../../components/HelpDesk';
import Footer from '../../../components/Footer';


export default function Help() {
  const navigate = useNavigate();

  return (
    <>
      <Header/>
      <HelpDesk/>       
      <Footer/>   
    </>
  )
}