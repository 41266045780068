import Header from '../../../components/Header'
import UserMenu from '../../../components/UserMenu'
import Footer from '../../../components/Footer'

export default function Home() {
  return (
    <>
        <Header/>
        <UserMenu/>
        <Footer/>
    </>
  )
}