import Header from '../../../components/Header'
import styles from './Profile.module.scss'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useContext, useEffect, useState } from 'react'
import api, { getCities, getStates, getCountries } from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from 'react-toastify'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';
import { AuthContext } from '../../../contexts/AuthContext'
import Footer from '../../../components/Footer'
import { FaSearchLocation } from "react-icons/fa";


export default function Profile() {
  const [update, setUpdate] = useState(1);
  const { user, updateUserLocalStorage } = useContext(AuthContext)
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [idestado, setIdEstado] = useState(null)
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCep, setLoadingCep] = useState(false);
  const [error, setError] = useState(false);
  const [fieldValidations, setFieldValidations] = useState({
    name: true,
    cpf: true,
    email: true,
    telefone: false,
    datanascimento: true,
    cep: true,
    endereco: true,
    bairro: true,
    cidade: true,
    estado: true,
    // pais: true
  });

  const updateFieldValidation = (fieldName, isValid) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: isValid,
    }));
  };

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    getValues,
    formState: { errors },
    } = useForm();

    useEffect(() => {
      setLoading(true);
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    async function getData (){
      // const countries = await getCountries(); 
      const states = await getStates(); 
      // setCountries(countries);
      setStates(states);

      let cidadeid = 0;


     api.get('profile/')
    .then(res => {
      if(res.data.sucesso){
        cidadeid= res.data.retorno.idcidade;
          reset({
            name: res.data.retorno.nome,
            cpf: res.data.retorno.cpf,
            email: res.data.retorno.email,
            telefone: res.data.retorno.celular,
            nascimento: res.data.retorno.nascimento,
            cep: res.data.retorno.cep,
            endereco: res.data.retorno.endereco,
            bairro: res.data.retorno.bairro,
            cidade: res.data.retorno.idcidade,
            estado: res.data.retorno.idestado,
            pais: res.data.retorno.idpais,
            datanascimento: res.data.retorno.datanascimento
          })

          setIdEstado(res.data.retorno.idestado);

          if(user !== res.data.retorno.nome) {
            updateUserLocalStorage(res)
          }

        }else{
          toast.error(res.data.mensagem);
        }
        setLoading(false);
      }).catch(err => {
        toast.error(err);
        setError(true);
        setLoading(false);
      })

      const cities = await getCities(idestado); 
      setCities(cities);
      reset({ cidade: cidadeid});

    }

  function getInfoCep(cep){
    setLoadingCep(true);
    api.post('cep', {
      'cep': cep
    }).then((res) => {
      console.log(res.data.retorno)
      if(res.data.sucesso){
        reset({
          cidade: res.data.retorno.idcidade,
          estado: res.data.retorno.idestado,
          endereco: res.data.retorno.logradouro,
          bairro: res.data.retorno.bairro
        })
        setLoadingCep(false);
      }

    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setLoadingCep(false);
    })
  }

  async function sendData(data){
    console.log(fieldValidations);
    setLoading(true);

    let response = await api.post('/profile/', {
      nome: data.name,
      cpf: data.cpf,
      email: data.email,
      celular: data.telefone,
      nascimento: data.nascimento,
      cep: data.cep,
      endereco: data.endereco,
      bairro: data.bairro,
      idcidade: data.cidade,
      idpais: data.pais,
      datanascimento: data.datanascimento
    })

    

    if(!response.data.sucesso){
      toast.error("Erro ao atualizar perfil, tente novamente.");
      setLoading(false);
      return;
    }

    setLoading(false);
    setUpdate(update + 1)
    toast.success("Perfil atualizado.");
  }

  const validateCPF = (cpf) => {
    const patternWithSeparators = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    if (patternWithSeparators.test(cpf)) {
      return true;
    }
  
    const patternWithoutSeparators = /^\d{11}$/;
    if (patternWithoutSeparators.test(cpf)) {
      return true;
    }
  
    return false;
  };

  const validateCelular = (celular) => {
    const patternWithSeparators = /^\(\d{2}\) \d{5}-\d{4}$/;
    if (patternWithSeparators.test(celular)) {
      return true;
    }
  
    const patternWithoutSeparators = /^\d{11}$/;
    if (patternWithoutSeparators.test(celular)) {
      return true;
    }
  
    return false;
  };

  return (
    <>
        <Header/>

        { error ? 'Erro ao buscar dados.' : 

          <>
          { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

          <div className={styles.container}>

            <div className={styles.headerList}>
              <h2><CgProfile/> Meus dados</h2>
              <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(sendData)}>

            <div className={`${styles.inputbox} ${fieldValidations.name ? '' : styles.invalid}`}>
              <span>Nome *</span>
              <input disabled type="text" name="name" placeholder="Nome"
              {...register("name")}
              />
            </div>

            {errors.name && (
              <small className={styles.textDanger}>{errors.name.message}</small>
            )}

            <div className={`${styles.inputbox} ${fieldValidations.cpf ? '' : styles.invalid}`}>
              <span>CPF *</span>
              <InputMask disabled mask="999.999.999-99"
              type="text" name="cpf" placeholder="CPF"
              {...register("cpf")}
              />
            </div> 

            {errors.cpf && (
              <small className={styles.textDanger}>{errors.cpf.message}</small>
            )}

            <div className={`${styles.inputbox} ${fieldValidations.email ? '' : styles.invalid}`}>
              <span>E-mail *</span>
              <input type="text" name="email" placeholder="E-mail"
              {...register("email", { required: "O email é obrigatório",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email inválido",
                }
              })}
              onKeyUp={() => {
                trigger("email").then((isValid) => {
                  updateFieldValidation('email', isValid);
                });
              }}
              />
            </div>

            {errors.email && (
              <small className={styles.textDanger}>{errors.email.message}</small>
            )}

            <div className={`${styles.inputbox}`}>
              <span>Telefone *</span>
              <InputMask mask="(99) 99999-9999"
              type="text" name="telefone" placeholder="Telefone"
              {...register("telefone", { required: "O telefone é obrigatório",
                pattern: {
                  message: "Telefone inválido",
                  value: validateCelular
                }
              })}
              onKeyUp={() => {
                trigger("telefone").then((isValid) => {
                  updateFieldValidation('telefone', isValid);
                });
              }}
              />
            </div>

            {errors.telefone && (
              <small className={styles.textDanger}>{errors.telefone.message}</small>
            )}


            <div className={`${styles.inputbox} ${fieldValidations.datanascimento ? '' : styles.invalid}`}>
              <span>Data de nascimento *</span>
              <input disabled type="date" name="datanascimento" placeholder="nascimento"
              {...register("datanascimento")}
              onChange={() => {
                trigger("datanascimento").then((isValid) => {
                  updateFieldValidation('datanascimento', isValid);
                });
              }}
              />
            </div>

            
            {errors.datanascimento && (
              <small className={styles.textDanger}>{errors.datanascimento.message}</small>
            )}

            <div className={`${styles.inputbox} ${fieldValidations.cep ? '' : styles.invalid}`}>
              <span>CEP *</span>
              <InputMask  mask="99999-999" type="text" name="CEP" placeholder="CEP"
              {...register("cep", { required: "O CEP é obrigatório",
                pattern: {
                  value: /\d{5}-\d{3}/,
                  message: "CEP inválido",
                }
              })}
              onKeyUp={() => {
                trigger("cep").then((isValid) => {
                  console.log(fieldValidations);
                  updateFieldValidation('cep', isValid);
                });

              }}
              />
              <button className={styles.buttonCep} type="button" onClick={() => getInfoCep(getValues('cep'))}>
              { loadingCep ?
              <div className={styles.loadingCep}><AiOutlineLoading/></div> :
              <FaSearchLocation/>
              }
              </button>
            </div>

            {errors.cep && (
              <small className={styles.textDanger}>{errors.cep.message}</small>
            )}

            <div className={`${styles.inputbox} ${fieldValidations.endereco ? '' : styles.invalid}`}>
              <span>Endereço com número *</span>
              <input type="text" name="endereco" placeholder="Endereço com número"
              {...register("endereco", { required: "O endereço é obrigatório",
                minLength: {
                  value: 10,
                  message: "Mínimo 10 caracteres",
                }
              })}
              onKeyUp={() => {
                trigger("endereco").then((isValid) => {
                  updateFieldValidation('endereco', isValid);
                });
              }}
              />
            </div>

            {errors.endereco && (
              <small className={styles.textDanger}>{errors.endereco.message}</small>
            )}

            <div className={`${styles.inputbox} ${fieldValidations.bairro ? '' : styles.invalid}`}>
              <span>Bairro *</span>
              <input type="text" name="bairro" placeholder="Bairro"
              {...register("bairro", { required: "O bairro é obrigatório",
                minLength: {
                  value: 2,
                  message: "Mínimo 2 caracteres",
                }
              })}
              onKeyUp={() => {
                trigger("bairro").then((isValid) => {
                  updateFieldValidation('bairro', isValid);
                });
              }}
              />
            </div>

            {errors.bairro && (
              <small className={styles.textDanger}>{errors.bairro.message}</small>
            )}

          {/* <div className={`${styles.inputbox} ${!fieldValidations.pais ? styles.invalid : ''}`}>
            <span>País *</span>
            <select
              name="pais"
              {...register("pais", {
                required: "O país é obrigatório",
              })}
              onBlur={() => {
                trigger("pais").then((isValid) => {
                  updateFieldValidation("pais", isValid);
                });
              }}
            >
              <option value="">Selecione</option>
              {countries?.map(c => (
                  <option key={c.id} value={c.id}>
                    {c.nome}
                  </option>
                ))}
            </select>
          </div>

          {errors.pais && <p className={styles.textDanger}>{errors.pais.message}</p>} */}

          <div className={`${styles.inputbox} ${!fieldValidations.estado ? styles.invalid : ''}`}>
            <span>Estado *</span>
            <select 
             {...register("estado", { required: "O estado é obrigatório" })}
             onBlur={() => {
               trigger("estado").then((isValid) => {
                 updateFieldValidation("estado", isValid);
               });      
             }}                    
            >
              <option value="">Selecione</option>
              {states?.map(c => (
                  <option key={c.id} value={c.id}>
                    {c.nome}
                  </option>
                ))}
            </select>
          </div>

          {errors.estado && <p className={styles.textDanger}>{errors.estado.message}</p>}

            <div className={`${styles.inputbox} ${!fieldValidations.cidade ? styles.invalid : ''}`}>
              <span>Cidade*</span>
              <select name="cidade" 
              {...register("cidade", { required: "A cidade é obrigatória" })}
              onBlur={() => {
                trigger("cidade").then((isValid) => {
                  updateFieldValidation("cidade", isValid);
                });      
              }}
              >
                <option value="">Selecione</option>
                {cities?.map(c => (
                  <option key={c.id} value={c.id}>
                    {c.nome}
                  </option>
                ))}
              </select>
            </div>

            {errors.cidade && (
              <small className={styles.textDanger}>{errors.cidade.message}</small>
            )}

            <button type="submit">Salvar</button>
            </form>
          </div>

          }
          </>
        }
        <Footer/>
    </>
  )
}