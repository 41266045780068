import Footer from '../../../../components/Footer';
import Header from '../../../../components/Header'
import HelpDeskChat from '../../../../components/HelpDeskChat';


export default function Chat() {
  return (
    <>
      <Header/>
      <HelpDeskChat/>          
      <Footer/>
    </>
  )
}