import Login from "./pages/Guest/Login";
import Register from "./pages/Guest/Register";
import Home from "./pages/Logged/Home";
import Quotes from "./pages/Logged/Quotes";
import Shopping from "./pages/Logged/Shopping";
import Finances from "./pages/Logged/Finances"
import Profile from "./pages/Logged/Profile";
import PasswordRecover from "./pages/Guest/PasswordRecover";
import QuoteItemDetail from "./pages/Logged/Quotes/detail/"
import ShoppingItemDetail from "./pages/Logged/Shopping/detail/"
import Bills from "./pages/Logged/Finances/Bills";
import Renegotiation from "./pages/Logged/Finances/Renegotiation";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContextProvider } from "./contexts/AuthContext";
import { FirstLoginContextProvider } from "./contexts/FirstLoginContext";
import { LimitAccessContextProvider } from "./contexts/LimitAccessContext";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Help from "./pages/Logged/Help";
import Chat from "./pages/Logged/Help/Chat";
import New from "./pages/Logged/Help/New";
import SacEmail from "./pages/Logged/SacEmail";
import Notifications from "./pages/Logged/Notifications";
import CustomerSuccess from "./pages/Logged/CustomerSuccess";
import FirstLoginPage from "./pages/Logged/FirstLogin";
import RecoverSetPage from "./pages/Guest/RecoverSet";
import SuccessPassword from "./pages/Guest/SuccessPassword";
import ValidatePage from "./pages/Guest/Validate";

function App() {
  return (
    <>
     <ToastContainer/>
        <Router>
          <AuthContextProvider>
            <FirstLoginContextProvider>
            <LimitAccessContextProvider>
            <Routes>
              <Route exact path="/" element={<Home />}/>
              <Route exact path="/quotes" element={<Quotes/>}/>
              <Route exact path="/quotes/detail/:id" element={<QuoteItemDetail/>}/>
              <Route exact path="/shopping" element={<Shopping/>}/>
              <Route exact path="/shopping/detail/:id/:documents?" element={<ShoppingItemDetail/>}/>
              {/* financeiro */}
              <Route exact path="/finances" element={<Finances/>}/>
              <Route exact path="/bills" element={<Bills/>}/>
              <Route exact path="/renegotiation/:id?" element={<Renegotiation/>}/>
              <Route exact path="/profile" element={<Profile/>}/>
              {/* Chat */}
              <Route exact path="/helpdesk" element={<Help/>}/>
              <Route exact path="/helpdesk/chat/:id" element={<Chat/>}/>
              <Route exact path="/helpdesk/new/:seguros?" element={<New/>}/>
              <Route exact path="/sacemail" element={<SacEmail/>}/>
              {/*notifications*/}
              <Route exact path="/customersuccess/:redirect?" element={<CustomerSuccess/>}/>
              {/*notifications*/}
              <Route exact path="/notifications/" element={<Notifications/>}/>
              {/*firstlogin*/}
              <Route exact path="/firstlogin" element={<FirstLoginPage/>}/>              
              {/*guest*/}
              <Route exact path="/login" element={<Login/>}/>
              <Route exact path="/register" element={<Register/>}/>
              <Route exact path="/passwordrecover" element={<PasswordRecover/>}/>
              <Route exact path="/successpassword" element={<SuccessPassword/>}/>
              <Route exact path="/validate/:_nonce" element={<ValidatePage/>}/>
              <Route exact path="/recoverset/:_nonce" element={<RecoverSetPage/>}/>

            </Routes>
            </LimitAccessContextProvider>
          </FirstLoginContextProvider>
          </AuthContextProvider>

          <Routes>

          </Routes>
        </Router>

    </>

  );
}

export default App;
