import Header from '../../../components/Header'
import styles from './SuccessPassword.module.scss'
import Footer from '../../../components/Footer';
import { RiMailCheckFill } from "react-icons/ri";

export default function SuccessPassword() { 
  return (
    <>
      <Header/>
      <div className={styles.container}>
        <div className={styles.box}>
          <RiMailCheckFill />
          <h3>Quase lá! Enviamos um link de ativação para o seu e-mail.</h3>
        </div>
      </div>
      <Footer/>
    </>
  )
}