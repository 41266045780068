import styles from './ShoppingList.module.scss'
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'
import { useState, useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BiShoppingBag } from "react-icons/bi";
import { IoDocumentsSharp } from "react-icons/io5";

function ShoppingList() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    async function getQuotes() {
      let items = await api.post('/quotes/0/100/3');
      if(items.data.sucesso) {
        setItems(items.data.retorno);
        setLoading(false);
      }else{
        console.log('errror');
        setLoading(false);
      }
    }
    getQuotes();
  }, [])

  const navigate = useNavigate();
  return (
    <>
    { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><BiShoppingBag/> Minhas compras</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      <div className={styles.list}>
        {items.map(item => {
          return <>
           <div className={styles.listItem}>
            <div className={styles.subItem}>
              <div>
                <span className={styles.orange}>Compra nº</span>
                <span className={styles.grey}>{item.id}</span>
              </div>
              <div>
                <span className={styles.orange}>Data</span>
                <span className={styles.grey}>{item.datalancamento}</span>
              </div>
            </div>

            <div className={`${styles.subItem} ${styles.border}`}>
              <div>
                <span>Origem do orçamento</span>
                <span className={styles.grey}>{item.unidade}</span>
              </div>
              <div>
                <span>Consultor CI</span>
                <span className={styles.grey}>{item.consultor}</span>
              </div>
            </div>

            <div className={styles.subItem}>
              <div>
                <span>Tipo intercâmbio</span>
                <span className={styles.grey}>{item.tipoprograma}</span>
              </div>
            </div>

            {/* <div className={styles.subItem}>
              <div>
                <span>Escola</span>
                <span className={styles.grey}>{item.escola}</span>
              </div>
            </div> */}

            <div className={styles.subItem}>
              <div>
                <span>Destino</span>
                <span className={styles.grey}>{item.destino}</span>
              </div>
              <div>
                <span>Período programa</span>
                <span className={styles.grey}>{item.deate}</span>
              </div>
            </div>

            <div className={styles.subItem}>
              <div>
                <span>Fornecedor</span>
                <span className={styles.grey}>{item.fornecedor}</span>
              </div>
            </div>

            <div className={styles.total}>
              <span>Valor total:</span>
              <span className={styles.orange}>{item.moeda} {item.valortotal}</span>
            </div>
            {/* <button onClick={() => navigate('/quotes/detail/' + item.id)}>Visualizar orçamento completo</button> */}
            <div className={styles.bottomButtons}> 
              <button onClick={() => navigate('/shopping/detail/' + item.id)}><BiShoppingBag/> Visualizar compra completa</button>
              <button onClick={() => navigate('/shopping/detail/' + item.id + '/1')}><IoDocumentsSharp/> Ver documentos</button>
            </div>

          </div> 
        </>
      })}     
      </div>
    </div>
    }
    </>

  );
}

export default ShoppingList;
