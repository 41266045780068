import Header from '../../../components/Header'
import { useNavigate } from 'react-router-dom'

import RecoverSet from '../../../components/RecoverSet';
import Footer from '../../../components/Footer';


export default function RecoverSetPage() {
  const navigate = useNavigate();

  return (
    <>
      <Header/>
      <RecoverSet/>          
      <Footer/>
    </>
  )
}