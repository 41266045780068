import Header from '../../../components/Header'
import styles from './Register.module.scss'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useEffect, useState } from 'react'
import api, { getCities, getCountries } from '../../../services/api'
import { toast } from 'react-toastify'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Footer from '../../../components/Footer'

export default function Register() {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fieldValidations, setFieldValidations] = useState({
    name: true,
    cpf: true,
    cnpj: true,
    email: true,
    sexo: true,
    telefone: true,
    nascimento: true,
    cep: true,
    endereco: true,
    bairro: true,
    cidade: true,
    pais: true
  });
  const [isCompany, setCompany] = useState(false); // É pessoa jurídica?

  const updateFieldValidation = (fieldName, isValid) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: isValid,
    }));
  };

  const handleChangeCompany = (e) => {
    console.log(e);
    if(e.target.value === 'cpf'){
      setCompany(false)
      return;
    }
    setCompany(true)
  }

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    } = useForm();

    useEffect(() => {
      setLoading(true);
      const countries = getCountries(); 
      const cities = getCities(); 
      setCountries(countries);
      setCities(cities);
      setLoading(false);
    }, [])

  async function sendData(data){
    setLoading(true);

    let response = await api.post('/register/', {
      name: data.name,
      cpf: data.cpf,
      cnpj: data.cnpj,
      sexo: data.sexo,
      email: data.email,
      telefone: data.telefone,
      nascimento: data.nascimento,
      cep: data.cep,
      endereco: data.endereco,
      bairro: data.bairro,
      cidade: data.cidade,
      pais: data.pais
    })

    if(response.status !== 200){
      toast.error("Erro ao cadastrar, tente novamente.");
      setLoading(false);
      return;
    }

    setLoading(false);
    toast.success("Cadastro concluído com sucesso.");
    // Redirect login
  }

  return (
    <>
        <Header/>

        { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

        <div className={styles.container}>

          <h2> Preencha os dados para continuar</h2>

          <form className={styles.form} onSubmit={handleSubmit(sendData)}>

          { !isCompany ?

          <>
            <div className={`${styles.inputbox} ${fieldValidations.cpf ? '' : styles.invalid}`}>
              <span>CPF *</span>
              <InputMask mask="999.999.999-99"  
              type="text" name="cpf" placeholder="CPF"
              {...register("cpf", { required: "O CPF é obrigatório",
                pattern: {
                  value: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                  message: "CPF inválido",
                }
              })}

              onKeyUp={() => {
                trigger("cpf").then((isValid) => {
                  updateFieldValidation('cpf', isValid);
                });
              }}
              />
            </div>

            <div className={styles.radiobox}>
            <label htmlFor="cpf">
                <input id="cpf" type="radio" value="cpf" name="pessoa"
                checked={!isCompany}
                onChange={handleChangeCompany}
                />
                <span>CPF</span>
            </label>
            <label htmlFor="cnpj">
                <input id="cnpj" type="radio" value="cnpj" name="pessoa"
                checked={isCompany}
                onChange={handleChangeCompany}
                />
                <span>CNPJ</span>
            </label>
            </div>

            {errors.cpf && (
              <small className={styles.textDanger}>{errors.cpf.message}</small>
            )}

          </>

          :

          <>
          <div className={`${styles.inputbox} ${fieldValidations.cnpj ? '' : styles.invalid}`}>
            <span>CNPJ *</span>
            <InputMask mask="99.999.999/9999-99"  
            type="text" name="cnpj" placeholder="CNPJ"
            {...register("cnpj", { required: "O CNPJ é obrigatório",
              pattern: {
                value: /^\d{2}\.\d{3}\.\d{3}\/\d{4}$-\d{2}/,
                message: "CNPJ inválido",
              }
            })}

            onKeyUp={() => {
              trigger("cnpj").then((isValid) => {
                updateFieldValidation('cnpj', isValid);
              });
            }}
            />
          </div>

          <div className={styles.radiobox}>
            <label htmlFor="cpf">
                <input id="cpf" type="radio" value="cpf" name="pessoa"
                checked={!isCompany}
                onChange={handleChangeCompany}
                />
                <span>CPF</span>
            </label>
            <label htmlFor="cnpj">
                <input id="cnpj" type="radio" value="cnpj" name="pessoa"
                checked={isCompany}
                onChange={handleChangeCompany}
                />
                <span>CNPJ</span>
            </label>
          </div>

          {errors.cnpj && (
            <small className={styles.textDanger}>{errors.cnpj.message}</small>
          )}
        </>
          
          }

          <div className={`${styles.inputbox} ${fieldValidations.name ? '' : styles.invalid}`}>
            <span>Nome *</span>
            <input type="text" name="name" placeholder="Nome"
            {...register("name", { required: "O nome é obrigatório",
              minLength: {
                value: 2,
                message: "Mínimo 2 caracteres",
              }
             })}
            onKeyUp={() => {
              trigger("name").then((isValid) => {
                updateFieldValidation('name', isValid);
              });
            }}
            />
          </div>

          {errors.name && (
            <small className={styles.textDanger}>{errors.name.message}</small>
          )}

          <h6>Sexo *</h6>
          <div className={styles.radiobox}>
            <label htmlFor="feminino">
                <input id="feminino" type="radio" value="F" name="sexo"/>
                <span>Feminino</span>
            </label>
            <label htmlFor="masculino">
                <input id="masculino" type="radio" value="M" name="sexo"/>
                <span>Masculino</span>
            </label>
            <label htmlFor="nao_informar">
                <input id="nao_informar" type="radio" value="NI" name="sexo" defaultChecked/>
                <span>Não informar</span>
            </label>
          </div>

          <div className={`${styles.inputbox} ${fieldValidations.email ? '' : styles.invalid}`}>
            <span>E-mail *</span>
            <input type="text" name="email" placeholder="E-mail"
            {...register("email", { required: "O email é obrigatório",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Email inválido",
              }
            })}
            onKeyUp={() => {
              trigger("email").then((isValid) => {
                updateFieldValidation('email', isValid);
              });
            }}
            />
          </div>

          {errors.email && (
            <small className={styles.textDanger}>{errors.email.message}</small>
          )}

          <div className={`${styles.inputbox} ${fieldValidations.telefone ? '' : styles.invalid}`}>
            <span>Telefone *</span>
            <InputMask mask="(99) 99999-9999"
            type="text" name="telefone" placeholder="Telefone"
            {...register("telefone", { required: "O telefone é obrigatório",
              pattern: {
                message: "Telefone inválido",
                value: /^\(\d{2}\) \d{5}-\d{4}$/
              }
            })}
            onKeyUp={() => {
              trigger("telefone").then((isValid) => {
                updateFieldValidation('telefone', isValid);
              });
            }}
            />
          </div>

          {errors.telefone && (
            <small className={styles.textDanger}>{errors.telefone.message}</small>
          )}


          <div className={`${styles.inputbox} ${fieldValidations.nascimento ? '' : styles.invalid}`}>
            <span>Data de nascimento *</span>
            <input type="date" name="nascimento" placeholder="nascimento"
            {...register("nascimento", { required: "A data de nascimento é obrigatório",
              // pattern: {
              //   message: "Nascimento inválido",
              //   value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
              // }
            })}
            onChange={() => {
              trigger("nascimento").then((isValid) => {
                updateFieldValidation('nascimento', isValid);
              });
            }}
            />
          </div>

          
          {errors.nascimento && (
            <small className={styles.textDanger}>{errors.nascimento.message}</small>
          )}

          <div className={`${styles.inputbox} ${fieldValidations.cep ? '' : styles.invalid}`}>
            <span>CEP *</span>
            <InputMask  mask="99999-999" type="text" name="CEP" placeholder="CEP"
            {...register("cep", { required: "O CEP é obrigatório",
              pattern: {
                value: /\d{5}-\d{3}/,
                message: "CEP inválido",
              }
            })}
            onKeyUp={() => {
              trigger("cep").then((isValid) => {
                updateFieldValidation('cep', isValid);
              });
            }}
            />
          </div>

          {errors.cep && (
            <small className={styles.textDanger}>{errors.cep.message}</small>
          )}

          <div className={`${styles.inputbox} ${fieldValidations.endereco ? '' : styles.invalid}`}>
            <span>Endereço com número *</span>
            <input type="text" name="endereco" placeholder="Endereço com número"
            {...register("endereco", { required: "O endereço é obrigatório",
              minLength: {
                value: 10,
                message: "Mínimo 10 caracteres",
              }
            })}
            onKeyUp={() => {
              trigger("endereco").then((isValid) => {
                updateFieldValidation('endereco', isValid);
              });
            }}
            />
          </div>

          {errors.endereco && (
            <small className={styles.textDanger}>{errors.endereco.message}</small>
          )}

          <div className={`${styles.inputbox} ${fieldValidations.bairro ? '' : styles.invalid}`}>
            <span>Bairro*</span>
            <input type="text" name="bairro" placeholder="Bairro"
            {...register("bairro", { required: "O bairro é obrigatório",
              minLength: {
                value: 2,
                message: "Mínimo 2 caracteres",
              }
            })}
            onKeyUp={() => {
              trigger("bairro").then((isValid) => {
                updateFieldValidation('bairro', isValid);
              });
            }}
            />
          </div>

          {errors.bairro && (
            <small className={styles.textDanger}>{errors.bairro.message}</small>
          )}

          <div className={`${styles.inputbox} ${!fieldValidations.cidade ? styles.invalid : ''}`}>
            <span>Cidade*</span>
            <select name="cidade" 
            {...register("cidade", { required: "A cidade é obrigatória" })}
            onBlur={() => {
              trigger("cidade").then((isValid) => {
                updateFieldValidation("cidade", isValid);
              });      
            }}
            >
              <option value="">Selecione</option>
              {cities?.map(c => (
                <option key={c.id} value={c.id}>
                  {c.description}
                </option>
              ))}
            </select>
          </div>

          {errors.cidade && (
            <small className={styles.textDanger}>{errors.cidade.message}</small>
          )}

        <div className={`${styles.inputbox} ${!fieldValidations.pais ? styles.invalid : ''}`}>
          <span>País *</span>
          <select
            name="pais"
            {...register("pais", {
              required: "O país é obrigatório",
            })}
            onBlur={() => {
              trigger("pais").then((isValid) => {
                updateFieldValidation("pais", isValid);
              });
            }}
          >
            <option value="">Selecione</option>
            {countries?.map(c => (
              <option key={c.id} value={c.id}>
                {c.description}
              </option>
            ))}
          </select>
        </div>

        {errors.pais && <p className={styles.textDanger}>{errors.pais.message}</p>}

          <button type="submit">cadastrar</button>
          </form>
        </div>

        }
        <Footer/>
    </>
  )
}