import styles from './BillsList.module.scss'
import { RiBillLine } from 'react-icons/ri';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters, AiOutlineArrowUp } from 'react-icons/ai';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { MdOutlineContentPaste, MdOutlineContentPasteOff } from 'react-icons/md';
import { GiConfirmed } from 'react-icons/gi';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../services/api'

function BillsList() {
  const [bills, setBills] = useState({});
  // const [billsIndices, setBills] = useState([]);
  const [billsOpen, setBillsOpen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [boletosOpen, setBoletosOpen] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    api.post('/finantial/')
    .then((res) => {
      let dataArray = [];
      let apiResponse =res.data.retorno;
      for (const key in apiResponse) {
        if (Object.hasOwnProperty.call(apiResponse, key)) {
          const dataObject = {
            id: key, // Set the 'id' property to the key (e.g., "522004")
            ...apiResponse[key], // Include the nested data
          };
          dataArray.push(dataObject);
        }
      }
      
      setBills(dataArray);
      console.log(dataArray);

      dataArray.map(x => {
        console.log(x['cartao'])
      });

      
      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    })
  }, [])

  function handleBoletos(id) {
    let newArr = [...boletosOpen];
    if(boletosOpen.includes(id)) {
      newArr.splice(boletosOpen.indexOf(id), 1);
      setBoletosOpen(newArr);

      return;
    }

    newArr.push(id);
    setBoletosOpen(newArr);
  }

 function handleDropDown(id){
  console.log(billsOpen, id);
    let newArr = [...billsOpen];
    if(billsOpen.includes(id)) {
      newArr.splice(billsOpen.indexOf(id), 1);
      setBillsOpen(newArr);

      return;
    }

    newArr.push(id);
    setBillsOpen(newArr);
 }
 
 

  return (
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><RiBillLine/> Meus pagamentos</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
      <>
          {bills?.length > 0 ? 

          <>
            {bills.map(bill => { 
            return  <>
            {bill !== undefined && 
              <div className={styles.saleWrapper} key={bill.key}>
                    <>
                    <div onClick={() => handleDropDown(bill.id)} className={styles.saleWrapperHeader}>
                      <h3>{bill.id}</h3>
                      { billsOpen.includes(bill.id) ? <BiSolidUpArrow/> : <BiSolidDownArrow/> }  
                    </div>
                    </>

                    { billsOpen.includes(bill.id) && 
                      <div className={styles.listWrapper}>
                        {

                          Object.keys(bill).map(b => {
                            console.log(b)
                            let loop = bill; 
                            return (
                              <>
                              { !['boletos', 'cartao', 'id'].includes(b) && 
                                <div className={styles.billBox}>
                                  <section>
                                    <h3>{loop[b].descricao}</h3>
                                    <article>
                                      <span><b>Parcela:</b> {loop[b].parcela}/{loop[b].totalparcelas}</span>
                                      <span><b>Vencimento:</b> {loop[b].datavencimento}</span>
                                      {loop[b].datacompensacao !== null && <span><b>Pago:</b> {loop[b].datacompensacao}</span> }
                                    </article>

                                    {loop[b].datacompensacao !== null ?
                                      <button style={{backgroundColor: '#00c1d5'}} onClick={() => window.open(loop[b].recibo, '_blank')}>Ver recibo <HiOutlineDocumentText/></button> :

                                      <>
                                      {loop[b].boleto !== '' && 
                                        <>
                                        {loop[b].status === 2 ?
                                        <button
                                        style={{backgroundColor: '#FC4040'}}
                                        onClick={() => window.open(loop[b].boleto, '_blank')}>Ver boleto <HiOutlineDocumentText/></button>
                                        : 
                                        <button
                                        style={{backgroundColor: '#808080'}}
                                        onClick={() => window.open(loop[b].boleto, '_blank')}>Ver boleto <HiOutlineDocumentText/></button>
                                        }
                                        </>
                                      }
                                      </>
                                      
                                    }

                                  </section>
                                  <div className={styles.valortotal}>
                                    <h3>{loop[b].valortotal}</h3>
                                  </div>
                                  <div className={styles.descMobile}>
                                    <h3>{loop[b].descricao}</h3>
                                  </div>
                                </div>                    
                                }            
                              </>
                              )
                              
                          })
                        }
                      
                        {bill['cartao'] !== undefined && 
                              <>
                                <div className={styles.billBox}>
                                <section>
                                  <h3>{bill['cartao'][0].descricao}</h3>
                                  <article>
                                    <span><b>Parcelas:</b> {bill['cartao'][0].totalparcelas}</span>
                                    <span><b>Data:</b> {bill['cartao'][0].datalancamento}</span>
                                  </article>

                                  {bill['cartao'][0].datacompensacao !== null ?
                                    <button style={{backgroundColor: '#00c1d5'}} onClick={() => window.open(bill['cartao'][0].recibo, '_blank')}>Ver recibo <HiOutlineDocumentText/></button> :
                                    ''
                                  }

                                  </section>
                                  <div className={styles.valortotal}>
                                    <h3>{bill['cartao'][0].valortotalcartao}</h3>
                                  </div>
                                  <div className={styles.descMobile}>
                                    <h3>{bill['cartao'][0].descricao}</h3>
                                  </div>
                                </div>
                              </>
                            
                          }
                        
                        { bill['boletos'] && 
                        <div className={styles.dropDownBoletos} >
                          <div className={styles.dropDownBoletosHeader} onClick={() => handleBoletos(bill.id)}>
                            <h3>Boletos</h3>
                            { boletosOpen.includes(bill.id)  ? <BiSolidUpArrow/> : <BiSolidDownArrow/> }                         
                            
                          </div>
                          {boletosOpen.includes(bill.id)  && 
                          <>
                          {
                          Object.keys(bill['boletos']).map(b => {
                            let loop = bill['boletos']; 
                            return (
                              <>
                                <div className={styles.billBox}>
                                <section>
                                  <h3>{loop[b].descricao}</h3>
                                  <article>
                                    <span><b>Parcela:</b> {loop[b].parcela}/{loop[b].totalparcelas}</span>
                                    <span><b>Vencimento:</b> {loop[b].datavencimento}</span>
                                    {loop[b].datacompensacao !== null && <span><b>Pago:</b> {loop[b].datacompensacao}</span> }
                                  </article>

                                  {loop[b].datacompensacao !== null ?
                                    <button style={{backgroundColor: '#00c1d5'}} onClick={() => window.open(loop[b].recibo, '_blank')}>Ver recibo <HiOutlineDocumentText/></button> :

                                    <>
                                      {loop[b].status === 2 ?
                                      <button
                                      style={{backgroundColor: '#FC4040'}}
                                      onClick={() => window.open(loop[b].boleto, '_blank')}>Ver boleto <HiOutlineDocumentText/></button>
                                      : 
                                      <button
                                      style={{backgroundColor: '#808080'}}
                                      onClick={() => window.open(loop[b].boleto, '_blank')}>Ver boleto <HiOutlineDocumentText/></button>
                                      }
                                    </>
                                  }

                                  </section>
                                  <div className={styles.valortotal}>
                                    <h3>{loop[b].valortotal}</h3>
                                  </div>
                                  <div className={styles.descMobile}>
                                    <h3>{loop[b].descricao}</h3>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                          </>
                          }
                        </div>
                        }
                      </div>     
                    }              
             </div> 
             }    
             </>
            })}
          </>
         :
              <>
                <div className={styles.listWrapper}>
                  <h3>Nenhum boleto encontrado para a venda selecionada.</h3>
                </div>
              </>
            }
        </>
      }
    </div>
  );
}

export default BillsList;
