import Header from '../../../components/Header'
import styles from './Login.module.scss'
import {useEffect, useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import Footer from '../../../components/Footer';

export default function Login() {
  const { signIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fieldValidations, setFieldValidations] = useState({
    email: true,
    password: true,
  });
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    } = useForm();

    const updateFieldValidation = (fieldName, isValid) => {
      setFieldValidations((prevValidations) => ({
        ...prevValidations,
        [fieldName]: isValid,
      }));
    };
  
    useEffect(() => {
      setLoading(false);
    }, [])

    async function sendData(data){
      signIn({
        email: data.email,
        password: data.password,
      })
    }

  return (
    <>
      <Header/>

      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

      <div className={styles.container}>
        <h3>Faça login para continuar</h3>
        <form className={styles.form} onSubmit={handleSubmit(sendData)}>
          <div className={`${styles.inputbox} ${fieldValidations.email ? '' : styles.invalid}`}>
            <span>E-mail *</span>
            <input type="text" name="email" placeholder="E-mail"
            {...register("email", { required: "O email é obrigatório",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Email inválido",
              }
            })}
            onKeyUp={() => {
              trigger("email").then((isValid) => {
                updateFieldValidation('email', isValid);
              });
            }}
            />
          </div>

          {errors.email && (
            <small className={styles.textDanger}>{errors.email.message}</small>
          )}

          <div className={`${styles.inputbox} ${fieldValidations.email ? '' : styles.invalid}`}>
            <span>Senha *</span>
            <input type="password" name="password" placeholder="Senha"
            {...register("password", { required: "A senha é obrigatória" })}
            onKeyUp={() => {
              trigger("password").then((isValid) => {
                updateFieldValidation('password', isValid);
              });
            }}
            />
          </div>

          {errors.password && (
            <small className={styles.textDanger}>{errors.password.message}</small>
          )}

          <div className={styles.passwordforgot}>
            <span  onClick={() => navigate('/passwordrecover')}>Esqueci minha senha</span>
          </div>

          <button>Entrar</button>
        </form>
      </div>

      }
    <Footer/>
    </>

  )
}