import styles from './FinancesMenu.module.scss'
import {
  Link
} from "react-router-dom";
import { RiBillLine } from "react-icons/ri";
import { FaMoneyBills } from "react-icons/fa6";
import { AuthContext } from '../../contexts/AuthContext';
import bg_finances from '../../images/bg_finances.png'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react';
import api from '../../services/api'
import financesIcon5 from '../../images/5.svg';
import { RiArrowGoBackFill } from 'react-icons/ri';

function FinancesMenu() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [pastDue, setPastDue] = useState(0);

  useEffect(() => {
    api.post('/finantialpastduemessage')
    .then((res) => {
      if(res.data.sucesso){
        setPastDue(res.data.retorno);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }, []);

  const bgStyle = {
    backgroundImage: `url(${bg_finances})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    overflow: 'hidden',
  };

  return (
    <>
    <div className={styles.bg} style={bgStyle}>
      <div>
        <h1>
          {pastDue === 0 && 'Seus pagamentos estão em dia.'}
          {pastDue === 1 && 'Você possui 1 pagamento em atraso!'}
          {pastDue >= 2 && 'Você possui pagamentos em atraso!'}
        </h1>
      </div>
    </div>
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2>
        <img src={financesIcon5} width={30} height={30}/>
        Financeiro</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      {/* <h2>Olá, {user?.nome}</h2> */}
      <div className={styles.list}>
        <Link to="/bills" className={styles.listItem}>
          <RiBillLine/>
          <h6>Meus pagamentos</h6>
        </Link>
        <Link to="/renegotiation" className={styles.listItem}>
          <FaMoneyBills/>
          <h6>Atrasados</h6>
        </Link>
      </div>
    </div>
    </>
  );
}

export default FinancesMenu;
