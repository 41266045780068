import Header from '../../../components/Header'
import { useNavigate } from 'react-router-dom'

import FirstLogin from '../../../components/FirstLogin';
import Footer from '../../../components/Footer';


export default function FirstLoginPage() {
  const navigate = useNavigate();

  return (
    <>
      <Header/>
      <FirstLogin/>          
      <Footer/>
    </>
  )
}