import styles from './NotificationsList.module.scss'
import { RiArrowGoBackFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters, AiFillNotification } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../services/api'
import notificacoesIcon3 from '../../images/3.svg';

function NotificationsList() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {  
    api.post('/notificationshistory')
    .then((res) => {
      setNotifications(res.data.retorno);
      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    })
  }, [])

  function handleClickNotification(not){
    api.post('/cleannotification/', {
      'idnotificacao': not.id
    }) .then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log('error', err);
    }).finally(() =>{
      navigate(not.rota);
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><img src={notificacoesIcon3} width={40} height={40}/> Minhas notificações</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
        <div className={styles.listWrapper}>

          {notifications.map(not => {
            return(
              <>
                <div className={styles.notificationBox}>
                  <div>
                    <span>{not.notificacao}</span>
                    <span style={{ color: '#818389' }} dangerouslySetInnerHTML={{ __html: not.mensagem }}/>
                    <div className={styles.divEmbbed}  />
                  </div>
                  {not.rota ? <button onClick={() => handleClickNotification(not)}>Ver</button> : '' }
                </div>
                <hr style={{ borderColor: '#000',  margin: 0 }}></hr>
              </>
            )
          })}
       
            
        </div>
      }
    </div>
  );
}

export default NotificationsList;
