import styles from './UserMenu.module.scss'
import {
  Link
} from "react-router-dom";
import { AiOutlineUnorderedList, AiFillSafetyCertificate } from "react-icons/ai";
import { BiShoppingBag, BiSupport } from "react-icons/bi";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { GoCrossReference } from "react-icons/go";
import { CgProfile } from "react-icons/cg";
import { AuthContext } from '../../contexts/AuthContext';
import bg_logado from '../../images/bg_logado.png'
import { useContext, useEffect, useState } from 'react';
import canada from '../../images/canada.jpg'
import api from '../../services/api'
import financesIcon5 from '../../images/5.svg';
import seguroIcon6 from '../../images/6.svg';
import embaixadorci7 from '../../images/7.svg';
import feedback from '../../images/feedback.svg';
import { LuConstruction } from "react-icons/lu";

function UserMenu() {
  const { user } = useContext(AuthContext);
  const [countDown, setCountDown] = useState({});

  useEffect(() => {
    api.post('/countdown')
    .then((res) => {
      setCountDown({
        'pais': res.data.retorno.sigla,
        'dias': res.data.retorno.dias
      })
    }).catch(err => {
      console.log(err);
    })
  }, [])

  const bgStyle = {
    backgroundImage: `url(${bg_logado})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    overflow: 'hidden',
  };

  return (
    <>
    <div className={styles.bg} style={bgStyle}>
      {countDown['dias'] !== undefined ? 
      <>
        <img className={styles.flag} src={countDown['pais']} width={150}/>
        <div>
          <h1>Faltam {countDown['dias']} dias para a <br></br>sua viagem!</h1>
          {/* <button>Ver detalhes</button> */}
        </div>
      </> : <h1>Seu intercâmbio começa aqui.</h1>}
    </div>
    <div className={styles.container}>
      <h2>Olá, {user?.nome}</h2>
      <div className={styles.list}>       
        <Link to="/shopping" className={styles.listItem}>
          <BiShoppingBag/>
          <h6>Minhas compras</h6>
        </Link>
        <Link to="/finances" className={styles.listItem}>
          {/* <RiMoneyDollarCircleFill/> */}
          <img src={financesIcon5} width={30} height={30}/>
          <h6>Financeiro</h6>
        </Link>
        <Link to="/quotes" className={styles.listItem}>
          <AiOutlineUnorderedList/>
          <h6>Meus orçamentos</h6>
        </Link>
        <Link to="/" className={styles.listItemInactive}>
          <LuConstruction />
          <h6>Em breve</h6>
        </Link>
      </div>
      <div className={styles.list}>
        <Link to="/helpdesk" className={styles.listItemFull}>
          <BiSupport/>
          <h6>Precisa de ajuda? Fale com um consultor.</h6>
        </Link>
        <Link to="/helpdesk/new/1" className={styles.listItemFull}>
        <img src={seguroIcon6} width={30} height={30}/>
          <h6>Adquira seu seguro.</h6>
        </Link>
        {/* <a href="https://intercare.ci.com.br" target="_blank" rel="noopener noreferrer" className={styles.listItemFull}> */}
          {/* <AiFillSafetyCertificate/> */}
          {/* <img src={seguroIcon6} width={30} height={30}/>
          <h6>Adquira seu seguro.</h6>
        </a> */}
      </div>
      <div className={styles.list}>
        <Link to="/sacemail" className={styles.listItemFull}>
          <img src={feedback} width={30} height={30}/>
          <h6>Feedback e Sugestões</h6>
        </Link>
        <a href="https://embaixador.ci.com.br/home" target="_blank" rel="noopener noreferrer" className={styles.listItemFull}>
          <img src={embaixadorci7} width={30} height={30}/>
          <h6>Embaixador CI.</h6>
        </a>
      </div>
    </div>
    </>
  );
}

export default UserMenu;
