import styles from './SacEmail.module.scss'
import { useForm } from 'react-hook-form'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { toast } from 'react-toastify'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { RiArrowGoBackFill } from 'react-icons/ri';
import feedback from '../../images/feedback.svg';

export default function SacEmail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fieldValidations, setFieldValidations] = useState({
    assunto: true,
    mensagem: true,
  });

  const updateFieldValidation = (fieldName, isValid) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: isValid,
    }));
  };

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    } = useForm();

    useEffect(() => {
      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  

  async function sendData(data){
    console.log(fieldValidations);
    setLoading(true);

    let response = await api.post('/sacemail', {
      mensagem: data.mensagem,
      assunto: data.assunto,
    })

    if(!response.data.sucesso){
      toast.error(response.data.mensagem);
      setLoading(false);
      return;
    }

    reset({
      mensagem: '',
      assunto: '',
    })
    setLoading(false);
    toast.success(response.data.mensagem);
  }



  return (
    <>

          <>
          { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

          <div className={styles.container}>

              <div className={styles.headerList}>
                <h2><img src={feedback} width={30} height={30}/> Feedback & Sugestões</h2>
                <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
              </div>

              <form className={styles.form} onSubmit={handleSubmit(sendData)}>

              <div className={`${styles.inputbox} ${fieldValidations.assunto ? '' : styles.invalid}`}>
                <span>Assunto *</span>
                <input type="text" name="assunto" placeholder="Assunto"
                {...register("assunto", { required: "O assunto é obrigatório",
                  minLength: {
                    value: 2,
                    message: "Mínimo 2 caracteres",
                  }
                })}
                onKeyUp={() => {
                  trigger("assunto").then((isValid) => {
                    updateFieldValidation('assunto', isValid);
                  });
                }}
                />
              </div>

              {errors.assunto && (
                <small className={styles.textDanger}>{errors.assunto.message}</small>
              )}

              <div className={`${styles.inputbox} ${fieldValidations.mensagem ? '' : styles.invalid}`}>
                <span>Mensagem *</span>
                <textarea  name="mensagem" placeholder="mensagem"
                {...register("mensagem", { required: "A mensagem é obrigatória",
                  minLength: {
                    value: 2,
                    message: "Mínimo 10 caracteres",
                  }
                })}
                onKeyUp={() => {
                  trigger("mensagem").then((isValid) => {
                    updateFieldValidation('mensagem', isValid);
                  });
                }}
                ></textarea>
              </div>

              {errors.mensagem && (
                <small className={styles.textDanger}>{errors.mensagem.message}</small>
              )}

              <button type="submit">Enviar</button>
              </form>
            </div>

          }
          </>        
    </>
  )
}