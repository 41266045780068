import Footer from '../../../../components/Footer'
import Header from '../../../../components/Header'
import ShoppingItemDetail from '../../../../components/ShoppingDetail'

export default function ShoppingDetail() {
  return (
    <>
        <Header/>
        <ShoppingItemDetail/>
        <Footer/>
    </>
  )
}