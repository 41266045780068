import styles from './Footer.module.scss'
import { PiTwitterLogoLight } from "react-icons/pi";
import {SlSocialInstagram, SlSocialFacebook} from 'react-icons/sl'
import { GrTiktok } from "react-icons/gr";
import { FaSpotify, FaTiktok  } from "react-icons/fa";
import {TiSocialYoutube} from 'react-icons/ti'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Footer(props) {
  // const [loading, setLoading] = useState(null);

  // useEffect(() => {
  //   if(props.isLoading){
  //     setLoading(true);
  //   }else{
  //     setLoading(false);
  //   }
  // }, [props.isLoading])

  return (
    <footer className={styles.footer}>
        <h3>Conecte-se com a CI</h3>
        <div className={styles.social}>
          <Link to="https://instagram.com/ci" target="_blank"><SlSocialInstagram /></Link>
          <Link to="https://open.spotify.com/show/5QmzKD5xEpFggkiXVmdSXF" target="_blank"><FaSpotify /></Link>
          <Link to="http://www.youtube.com/channel/UCiVUJJL7lf9U1Fel72zynpw?sub_confirmation=1" target="_blank"><TiSocialYoutube/></Link>
          <Link to="https://www.facebook.com/CI.Intercambio" target="_blank"><SlSocialFacebook/></Link>
          <Link to="https://www.tiktok.com/@ciintercambio" target="_blank"><FaTiktok /></Link>
        </div>
        <hr/>
        <div className={styles.copy}>Copyright © 2021 - 2024 - CI Central de Intercâmbio Viagens Ltda - CNPJ - 59.164.996/0003-85.</div>
    </footer>
  );
}

export default Footer;
