import Footer from '../../../../components/Footer';
import Header from '../../../../components/Header'
import HelpDeskNew from '../../../../components/HelpDeskNew';


export default function Chat() {
  return (
    <>
      <Header/>
      <HelpDeskNew/>          
      <Footer/>
    </>
  )
}