import styles from './PastDueBillsList.module.scss'
import { RiBillLine } from 'react-icons/ri';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters, AiOutlineArrowUp } from 'react-icons/ai';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { MdOutlineContentPaste, MdOutlineContentPasteOff } from 'react-icons/md';
import { GiConfirmed } from 'react-icons/gi';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../services/api'

function PastDueBillsList() {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showWarningLimitedAccess, setShowWarningLimitedAccess] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {  
    if(id !== undefined){
      setShowWarningLimitedAccess(true);
    }

    api.post('/finantial/true')
    .then((res) => {
      if(res.data.retorno.length === 0){
        return
      }

      setBills(Object.values(res.data.retorno));

      console.log(bills);

      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    })
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><RiBillLine/> Renegociações</h2>
        { !showWarningLimitedAccess && <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button> }
      </div>
      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
      <>
          {showWarningLimitedAccess &&
          <div className={styles.warning}>
            <span>Você possui débitos pendentes há mais de 15 dias, seu acesso está limitado. Faça o pagamento para regularizar sua situação.</span> 
          </div>
          }
          {bills?.length > 0 ? 

          <div className={styles.saleWrapper}>
            {bills.map(billInfo => {
            return <> {billInfo.idvenda !== undefined && 
                          <div className={styles.listWrapper} key={billInfo.idvenda}>                   
                              <div className={styles.billBox}>
                                <section>
                                  <h3>{billInfo.descricao} - #{billInfo.idvenda}</h3>
                                  <article>
                                    <span><b>Parcela:</b> {billInfo.parcela}/{billInfo.totalparcelas}</span>
                                    <span><b>Vencimento:</b> {billInfo.datavencimento}</span>
                                    {billInfo.datacompensacao !== null && <span><b>Pago:</b> {billInfo.datacompensacao}</span> }
                                  </article>

                                  {billInfo.datacompensacao !== null ?
                                    <button style={{backgroundColor: '#54B654'}} onClick={() => window.open(billInfo.recibo, '_blank')}>Ver recibo <HiOutlineDocumentText/></button> :

                                    <>
                                    {billInfo.boleto !== '' && 
                                      <>
                                      {billInfo.status === 2 ?
                                      <button
                                      style={{backgroundColor: '#FC4040'}}
                                      onClick={() => window.open(billInfo.boleto, '_blank')}>Ver boleto <HiOutlineDocumentText/></button>
                                      : 
                                      <button
                                      style={{backgroundColor: '#808080'}}
                                      onClick={() => window.open(billInfo.boleto, '_blank')}>Ver boleto <HiOutlineDocumentText/></button>
                                      }
                                      </>
                                    }
                                    </>

                                  }
                                </section>
                                <div className={styles.valortotal}>
                                  <h3>{billInfo.valortotal}</h3>
                                </div>
                                <div className={styles.descMobile}>
                                  <h3>{billInfo.descricao}</h3>
                                </div>
                              </div>                            
                        </div>
                      }
                    </>
            })}
          </div>
         :
              <>
                <div className={styles.listWrapper}>
                  <h3>Nenhum boleto atrasado encontrado.</h3>
                </div>
              </>
            }
        </>
      }
    </div>
  );
}

export default PastDueBillsList;
