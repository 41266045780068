import Header from '../../../components/Header'
import styles from './PasswordRecover.module.scss'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import api from '../../../services/api';
import Footer from '../../../components/Footer';

export default function PasswordRecover() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fieldValidations, setFieldValidations] = useState({
    email: true,
    password: true,
  });
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    } = useForm();

    const updateFieldValidation = (fieldName, isValid) => {
      setFieldValidations((prevValidations) => ({
        ...prevValidations,
        [fieldName]: isValid,
      }));
    };
  
    useEffect(() => {
      setLoading(false);
    }, [])


    async function sendData(data){
      setLoading(true);

      let response = await api.post('/recover', {
        email: data.email
      })
  
      if(!response.data.sucesso){
        toast.error(response.data.mensagem);
        setLoading(false);
        return;
      }
  
      setLoading(false);
      toast.success(response.data.mensagem);
    }

  return (
    <>
      <Header/>

      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

      <div className={styles.container}>
        <h3>Recuperar senha</h3>
        <form className={styles.form} onSubmit={handleSubmit(sendData)}>
          <div className={`${styles.inputbox} ${fieldValidations.email ? '' : styles.invalid}`}>
            <span>E-mail *</span>
            <input type="text" name="email" placeholder="E-mail"
            {...register("email", { required: "O email é obrigatório",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Email inválido",
              }
            })}
            onKeyUp={() => {
              trigger("email").then((isValid) => {
                updateFieldValidation('email', isValid);
              });
            }}
            />
          </div>

          {errors.email && (
            <small className={styles.textDanger}>{errors.email.message}</small>
          )}

          <div className={styles.login}>
            <span  onClick={() => navigate('/login')}>Já tenho um cadastro</span>
          </div>

          <button>Enviar</button>
        </form>
      </div>

      }
      <Footer/>
    </>
  )
}