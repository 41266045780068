import {createContext, useState, useEffect } from 'react';
import api from '../services/api'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment/moment';

import { toast } from 'react-toastify';

export const AuthContext = createContext([]);

export function AuthContextProvider({ children }){
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [loginCheck, setLoginCheck] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function validateToken(){
    let current = moment();
    if(current.diff(loginCheck, 'minutes') >= 1){
      let isTokenValid = await api.post('/tokenvalidate')
      if(!isTokenValid.retorno.sucesso){
        localStorage.removeItem('CITOKEN')
        localStorage.removeItem('CIUSER')
        navigate('/login')
      }
    }
  }

  async function checkLogin(){
    const guestPages = [
      '/login',
      '/successpassword',
      '/passwordrecover',
      '/recoverset',
      '/register',
      '/validate',
    ];

    const currentPath = location.pathname;

    if(guestPages.includes(currentPath)){
      return;
    }

    if(currentPath.split('/')[1] === 'recoverset'){
      return;
    }

    if(currentPath.split('/')[1] === 'validate'){
      return;
    }



    const IsToken = localStorage.getItem('CITOKEN')
    const IsUser = localStorage.getItem('CIUSER')
    if(!IsToken){
      localStorage.removeItem('CITOKEN')
      localStorage.removeItem('CIUSER')
      navigate('/login')
    }else{
      try{
        let user = JSON.parse(IsUser);
        setUser(user)
        setToken(IsToken)
        validateToken();
      }catch(err){
        localStorage.removeItem('CITOKEN')
        localStorage.removeItem('CIUSER')
        navigate('/login')
      }
    }
  }

  function getToken(){
    const userToken = localStorage.getItem('CITOKEN')
    return userToken;
  }

  function updateUserLocalStorage(response){
    let user = {
      'nome': response.data.retorno.nome,
      'id': response.data.retorno.id
    };

    localStorage.setItem('CIUSER', JSON.stringify(user))

    setUser(user);
  }

  async function signIn({ email, password }){
      let response = await api.post('/auth/', {
        'email': email,
        'senha': password
      })

      if(response.data.sucesso) {
        setLoginCheck(moment());
        localStorage.setItem('CITOKEN', response.data.retorno.token)
        setToken(response.data.retorno.token);
        updateUserLocalStorage(response);
 
        const isRedirect = localStorage.getItem('CIREDIRECT');
        if(isRedirect) {
          navigate(isRedirect);
          localStorage.removeItem('CIREDIRECT');
        }else{
          navigate('/')
        }

        toast.success('Login bem sucedido')
      }else{
          toast.error(response.data.mensagem)
      }
  }  

  async function logOut(){   
    localStorage.removeItem('CITOKEN')
    localStorage.removeItem('CIUSER')

    setUser(null);
    setToken(null);

    navigate('/login')
    toast.success('Logout bem sucedido')    
  }

  return(
    <AuthContext.Provider value={{ user: user, updateUserLocalStorage: updateUserLocalStorage, signIn: signIn, logOut: logOut, getToken: getToken, setUser: setUser, checkLogin: checkLogin }}>
		 {children}
    </AuthContext.Provider>
  )
}