import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import NotificationsList from '../../../components/NotificationsList'

export default function Notifications() {
  return (
    <>
        <Header/>
        <NotificationsList/>
        <Footer/>
    </>
  )
}