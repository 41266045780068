import {createContext, useState, useEffect } from 'react';
import api from '../services/api'
import { useNavigate, useLocation } from 'react-router-dom'

export const LimitAccessContext = createContext([]);

export function LimitAccessContextProvider({ children }){
  const [limitedAccess, setLimitedAccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function verifyAccessToRedirect(){
    console.log('verify to redirect');
    const allowedPages = [
      '/renegotiation',
    ];

    const currentPath = location.pathname;
    if(!allowedPages.includes(currentPath)){
      navigate('/renegotiation/1');
      return;
    }
  }

  async function checkAccess(){
    const guestPages = [
      '/login',
      '/successpassword',
      '/passwordrecover',
      '/register',
      '/recoverset',
      '/validate'
    ];

    const currentPath = location.pathname;

    if(guestPages.includes(currentPath)){
      return;
    }

    if(currentPath.split('/')[1] === 'recoverset'){
      return;
    }

    if(currentPath.split('/')[1] === 'validate'){
      return;
    }

    console.log('checking acccess')
    let items = await api.post('/finantialpastdue');
    if(items.data.sucesso) {
      if(!items.data.retorno){
        setLimitedAccess(false);
        return;
      }
      setLimitedAccess(true);
      verifyAccessToRedirect();
    }else{
      console.log('errror');
    }
  }
  

  return(
    <LimitAccessContext.Provider value={{ 'limitedAccess': limitedAccess, 'checkAccess': checkAccess}}>
		 {children}
    </LimitAccessContext.Provider>
  )
}