import Header from '../../../components/Header'
import { useNavigate } from 'react-router-dom'

import Validate from '../../../components/Validate';
import Footer from '../../../components/Footer';


export default function ValidatePage() {
  const navigate = useNavigate();

  return (
    <>
      <Header/>
      <Validate/>          
      <Footer/>
    </>
  )
}