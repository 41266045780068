import styles from './Quiz.module.scss'
import { RiArrowGoBackFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdOutlineQuiz } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Quiz() {
  const [quiz, setQuiz] = useState('quiz');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {  
    setLoading(false);
    // const quiz = 'quiz';

    // api.post('/quiz/')
    // .then((res) => {
    //   if(res.data.retorno.length === 0){
    //     return
    //   }

    //   setItems(res.data.retorno);

    //   setLoading(false);
    // }).catch((err) => {
    //   console.log(err)
    //   setLoading(false);
    // })
  }, [])



  return (
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><MdOutlineQuiz /> Meus questionários</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
      <>
          <div dangerouslySetInnerHTML={{ __html: quiz }} />
      </>
      }
    </div>
  );
}

export default Quiz;
