import {createContext, useState, useEffect } from 'react';
import api from '../services/api'
import { useNavigate, useLocation } from 'react-router-dom'

export const FirstLoginContext = createContext([]);

export function FirstLoginContextProvider({ children }){
  const [firstlogin, setFirstLogin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkFirstLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  async function checkFirstLogin(){

    let items = await api.post('/firstlogin');
    if(items.data.sucesso) {
      if(!items.data.retorno){
        setFirstLogin(false);
        return;
      }
      setFirstLogin(true);
      navigate('/firstlogin');
    }else{
      console.log('errror');
    }
  }
  

  return(
    <FirstLoginContext.Provider value={{ 'firstlogin': firstlogin, 'checkFirstLogin': checkFirstLogin}}>
		 {children}
    </FirstLoginContext.Provider>
  )
}