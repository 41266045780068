import styles from './RecoverSet.module.scss'
import { useForm } from 'react-hook-form'
import api from '../../services/api'
import { Router, useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { toast } from 'react-toastify'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { MdVerifiedUser } from "react-icons/md";

export default function RecoverSet() {
  const { _nonce } = useParams();
  console.log(_nonce)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fieldValidations, setFieldValidations] = useState({
    senha: true,
    repetirsenha: true,
  });

  const updateFieldValidation = (fieldName, isValid) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: isValid,
    }));
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    trigger,
    formState: { errors },
    } = useForm();

    useEffect(() => {
      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  

  async function sendData(data){
    console.log(fieldValidations);
    setLoading(true);

    let response = await api.post('/recoverchangepassword', {
      senha: data.senha,
      nonce: _nonce
    })

    if(!response.data.sucesso){
      toast.error(response.data.mensagem);
      setLoading(false);
      return;
    }

    setLoading(false);
    navigate('/successpassword');
    toast.success(response.data.mensagem);
  }

  return (
    <>

          <>
          { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

          <div className={styles.container}>

              <div className={styles.headerList}>
                <h2><MdVerifiedUser /> Recuperar senha</h2>
              </div>

              <form className={styles.form} onSubmit={handleSubmit(sendData)}>

                <h5>Insira sua nova senha.</h5>

              <div className={`${styles.inputbox} ${fieldValidations.senha ? '' : styles.invalid}`}>
                <span>Senha *</span>
                <input type="password" name="senha" placeholder="Senha"
                {...register("senha", { required: "A senha é obrigatória",
                  minLength: {
                    value: 8,
                    message: "Mínimo 8 caracteres",
                  }
                })}
                onKeyUp={() => {
                  trigger("senha").then((isValid) => {
                    updateFieldValidation('senha', isValid);
                  });
                }}
                />
              </div>

              {errors.senha && (
                <small className={styles.textDanger}>{errors.senha.message}</small>
              )}

              <div className={`${styles.inputbox} ${fieldValidations.repetirsenha ? '' : styles.invalid}`}>
                <span>Repetir senha *</span>
                <input type="password" name="repetirsenha" placeholder="Repetir senha"
                {...register("repetirsenha", { required: "Repetir a senha é obrigatório",
                  minLength: {
                    value: 8,
                    message: "Mínimo 8 caracteres",
                  },
                  validate: {
                    matchOtherPassword: value =>
                      value === getValues("senha") || "As senhas não coincidem",
                  }
                })}
                onKeyUp={() => {
                  trigger("repetirsenha").then((isValid) => {
                    updateFieldValidation('repetirsenha', isValid);
                  });
                }}
                />
              </div>

              {errors.repetirsenha && (
                <small className={styles.textDanger}>{errors.repetirsenha.message}</small>
              )}
              {errors.matchOtherPassword && (
                <small className={styles.textDanger}>{errors.matchOtherPassword.message}</small>
              )}

             

              <button type="submit">Enviar</button>
              </form>
            </div>

          }
          </>        
    </>
  )
}