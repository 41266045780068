import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import ShoppingList from '../../../components/ShoppingList'


export default function Shopping() {
  return (
    <>
        <Header/>
        <ShoppingList/>
        <Footer/>
    </>
  )
}